import * as React from 'react';
import PropTypes from 'prop-types';
import withInstagramFeed from 'origen-react-instagram-feed';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
import {FaInstagram} from 'react-icons/fa';

// import { propTypes } from 'gatsby-plugin-image/dist/src/components/static-image.server';

const InstaGrid = ({ className, imgClassName, linkClassName, media, account, status}) => {
  return (
    <Grid container spacing={2} className={className}>
      {media &&
        status === 'completed' &&
        media.map(({ displayImage, id, postLink, accessibilityCaption }) => (
            <Grid item xs={12} sm={6} md={4} key={id || displayImage}>
                <a href={postLink || `https://www.instagram.com/${account}/`} target='_blank'>
                    {/* <ButtonBase> */}
                    <img
                        src={displayImage}
                        alt={accessibilityCaption || 'Instagram picture'}
                        className={imgClassName}
                    />
                    {/* </ButtonBase> */}
                </a>
            </Grid>
        ))}
      {status === 'loading' && <p className={linkClassName}>Loading...</p>}
      {status === 'failed' && 
        <a href={`https://www.instagram.com/${account}/`} target='_blank'>
            <div className={linkClassName}>
                <div>
                    <FaInstagram className={`mr-2 inline transform scale-150`}/>
                    <p className='mt-0 pt-0 inline'>Check Instagram here</p>
                </div>
            </div>
        </a>}
    </Grid>
  );
};

const styles = () => ({
    wrapper: {},
    image: {
      width: '100%',
      height: '100%',
    },
  });

InstaGrid.defaultProps = {
  media: undefined,
  className : '',
  status : 'completed'
};

InstaGrid.propTypes = {
    media: PropTypes.arrayOf({
        displayImage: PropTypes.string,
        id: PropTypes.string,
        postLink: PropTypes.string,
        accessibilityCaption: PropTypes.string,
      }),
    account: PropTypes.string,
    className: PropTypes.string,
    imgClassName: PropTypes.string,
    linkClassName : PropTypes.string,
    status: PropTypes.oneOf(['completed','loading','failed']),
  }

export default compose(
  withInstagramFeed,
  withStyles(styles),
)(InstaGrid);