import React, {useState} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';

const NavBar = (props) => {

    const [isMenuOpen, setMenuOpen] = useState(false);
    return (
    <nav className="bg-gray-800 w-screen m-0 p-0 fixed z-40">
        <div className='flex flex-row items-center justify-center '>
            <div className="w-full xl:w-9/12 lg:py-6">
            {/* <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-12 lg:py-6 bg-red-600"> */}
                <div className="flex flex-col items-center justify-center h-16 w-full">

                    <div className="absolute inset-y-0 top-4 right-6 sm:right-8 flex lg:hidden h-9 items-center bg-linkColor">
                        {/* Mobile menu button */}
                        <button type="button" className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        aria-controls="mobile-menu" aria-expanded="false" onClick={(e) => {setMenuOpen(!isMenuOpen); e.preventDefault();}}>
                        <span className="sr-only">Open main menu</span>
                            {/* Icon when menu is closed. */}
                            {/*
                            Heroicon name: outline/menu
                
                            Menu open: "hidden", Menu closed: "block"
                        */}
                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                            {/* Icon when menu is open. */}
                            {/*               
                            Heroicon name: outline/x
                
                            Menu open: "block", Menu closed: "hidden"
                            */}
                            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin ="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <div className="flex w-full flex-row items-start justify-start lg:items-center lg:justify-center ">
                        <div className="flex w-6/12 h-14 lg:h-full ml-4 lg:pl-2 lg:w-4/12 ">
                            {/* <Link to='/' className="flex h-full w-72 bg-yellow-400"> */}
                            <Link to='/' className="relative flex h-full w-72 ">
                                <StaticImage src='../images/logo.png' alt='Home' className="w-36 h-12 lg:h-20 lg:w-64 m-0 " />
                                {/* <StaticImage src='../images/logo.png' alt='Home' className="m-0 bg-no-repeat bg-center object-contain h-12 lg:h-20" /> */}
                                {/* <img src="https://www.discgolfthailand.com/wp-content/uploads/2017/05/logo.png" className="m-0 bg-no-repeat bg-center object-contain h-12 lg:h-20" alt="Home"/> */}
                            </Link>
                        </div>
                        <div className="flex flex-col lg:w-8/12 ">
                            <div className="hidden lg:flex lg:flex-col sm:ml-6 items-center">
                                <div className="flex flex-row w-full space-x-4 pr-4 font-sans justify-end">
                                    {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                                    <Link to="/about" activeClassName="text-linkColor" className='text-gray-300 hover:text-linkColor px-3 py-2 rounded-md text-xs font-medium uppercase' >About</Link>
                                    <Link to="/#gallery" className="text-gray-300 hover:text-linkColor px-3 py-2 rounded-md text-xs font-medium uppercase">Gallery</Link>
                                    {/* <Link to="#" className="text-gray-300 hover:text-linkColor px-3 py-2 rounded-md text-xs font-medium uppercase">Reviews</Link> */}
                                    <Link to="/#location" className="text-gray-300 hover:text-linkColor px-3 py-2 rounded-md text-xs font-medium uppercase">Location</Link>
                                    <Link to="/videos" activeClassName="text-linkColor" className="text-gray-300 hover:text-linkColor px-3 py-2 rounded-md text-xs font-medium uppercase">Videos</Link>
                                    <Link to="/courses" activeClassName="text-linkColor" className="text-gray-300 hover:text-linkColor px-3 py-2 rounded-md text-xs font-medium uppercase">Courses</Link>
                                    <Link to="/#contactUs" activeClassName="text-linkColor" className='text-gray-300 hover:text-linkColor px-3 py-2 rounded-md text-xs font-medium uppercase' >Contact Us</Link>
                                    {/* <a href="#contactUs" onClick={() => {alert('ek');setcurrentMenu('contactUs')}}
                                    className="text-gray-300 hover:text-linkColor px-3 py-2 rounded-md text-xs font-medium uppercase">Contact Us</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="flex-shrink-0 flex items-center">
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    </div>
                </div>
            </div>
        </div>
    
        {/* Mobile menu, show/hide based on menu state. */}
        <div className={`${isMenuOpen ? '' : 'hidden' } lg:hidden`} id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1 font-sans">
                {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                <Link to="/about" activeClassName='text-linkColor' onClick={() => {setMenuOpen(false)}}
                    className='text-gray-300 hover:text-linkColor block px-3 py-2 rounded-md text-base font-medium' >About</Link>
                {/* <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-linkColor block px-3 py-2 rounded-md text-base font-medium">About</a> */}                
                <Link to="/#gallery" onClick={() => {setMenuOpen(false)}}
                    className="text-gray-300 hover:bg-gray-700 hover:text-linkColor block px-3 py-2 rounded-md text-base font-medium">Gallery</Link>
                {/* <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-linkColor block px-3 py-2 rounded-md text-base font-medium">Reviews</a> */}
                <Link to="#location"  onClick={() => {setMenuOpen(false)}}
                    className="text-gray-300 hover:bg-gray-700 hover:text-linkColor block px-3 py-2 rounded-md text-base font-medium">Location</Link>
                <Link to="/videos" activeClassName='text-linkColor' onClick={() => {setMenuOpen(false)}}
                    className="text-gray-300 hover:bg-gray-700 hover:text-linkColor block px-3 py-2 rounded-md text-base font-medium">Videos</Link>
                <Link to="/courses" activeClassName='text-linkColor' onClick={() => {setMenuOpen(false)}}
                    className="text-gray-300 hover:bg-gray-700 hover:text-linkColor block px-3 py-2 rounded-md text-base font-medium">Courses</Link>
                <Link to="/#contactUs" onClick={() => {setMenuOpen(false)}}
                    className="text-gray-300 hover:bg-gray-700 hover:text-linkColor block px-3 py-2 rounded-md text-base font-medium">Contact Us</Link>
            </div>
        </div>
    </nav>    
)}

NavBar.propTypes = {
    navBarTitle: PropTypes.string,
}

NavBar.defaultProps = {
    navBarTitle: ``,
}

export default NavBar
