import React, { useRef, useState } from "react"
import {
  FaFacebookF,
  FaYoutube,
  FaInstagram,
  FaGooglePlusG,
  FaDisc,
  FaTripadvisor,
  FaAirbnb,
} from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image"
import InstaGrid from "../components/insta-feeder"

const Footer = ({ title }) => {
  const [emailSubscribeValue, setemailSubscribeValue] = useState("")
  const [emailValMsg, setemailValMsg] = useState("")
  const email = useRef()

  const validateEmail = mail => {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      mail
    )
  }

  return (
    <div>
      <div className="flex flex-col bg-gray-800 py-10">
        <div className="flex flex-col lg:flex-row justify-center gap-x-1">
          <div className="flex w-full lg:w-4/12 xl:w-3/12">
            <div>
              <div className="m-3 bg-no-repeat bg-center object-contain w-14 float-left">
                <StaticImage src="../images/sm-logo.png" layout="constrained" alt="footer" />
              </div>
              <p className="p-4 text-gray-300 font-sans text-xs">
                Laem Sor Beach Disc Golf and Acoustic Cafe is Koh Samui's
                premiere 18 hole disc golf course. Located just 10 minutes south
                of Lamai, The Acoustic Cafe offers a relaxed atmosphere for
                people looking to break away from the crowds and explore the
                more natural side of Koh Samui. Surrounded by landscaped
                fairways and greens just off the beach, The Acoustic Cafe is
                built in the traditional Thai wooden style construction.
                Naturally, the tropical fruit trees, exotic birds and
                butterflies embellish the rustic atmosphere which make this a
                truly captivating place to visit. Food and drinks are available
                with live music on occasion in the evening time. Affordable
                accommodations available with teak wood guesthouse and treetop
                bungalow.
              </p>
            </div>
          </div>
          {/* <div className="flex pt-2 pb-10 px-2 w-full lg:w-4/12 xl:w-3/12">
                    <a href={`https://www.instagram.com/discgolfthailand/`} target='_blank'>
                        <div className='pl-4 pt-4 text-linkColor font-sans text-base'>
                            <div>
                                <FaInstagram className={`mr-2 inline transform scale-150`}/>
                                <p className='mt-0 pt-0 inline'>Check Instagram here</p>
                            </div>
                        </div>
                    </a> */}
          {/* <InstaGrid account="discgolfthailand" linkClassName='pl-4 pt-4 text-linkColor font-sans text-base' numberOfMediaElements={9} /> */}
          {/* </div> */}
          <div className="flex flex-col w-full lg:w-4/12 xl:w-3/12 pt-4 pl-8 pr-4 ">
            {/* <div className="pt-3">
                        <form>
                            <div className="flex flex-col gap-y-2">
                                <div className="text-gray-300 text-xs font-sans" >Email *</div>
                                <div>
                                    <input ref={email} type="text" onChange={(e) => {setemailSubscribeValue(e.target.value)}}
                                        className={`w-full p-1 border border-gray-300 text-xs font-sans text-gray-500 focus:outline-none
                                                    ${emailValMsg.trim() !== '' && 'border-red-600'}`}></input>
                                    <span className="text-xs text-red-600 font-sans">{emailValMsg}</span>
                                </div>
                                <div>
                                <button onClick={(e) => {
                                        if(!validateEmail(emailSubscribeValue))
                                        {
                                            email.current.focus();
                                            setemailValMsg('Provide correct email address')
                                        }
                                        else
                                            setemailValMsg('');
                                        e.preventDefault();
                                    }}
                                    className="bg-linkColor w-3/12 h-7 text-white font-sans text-xs opacity-80 hover:opacity-100">Subscribe!</button>
                                </div>
                            </div>              
                        </form>
                    </div> */}
            <div className="text-gray-300 font-sans text-xs py-1">
              <strong>Address:</strong> 25 moo 4, Tambon Na Mueang, Koh Samui,
              Chang Wat Surat Thani 84140, Thailand
            </div>
            <div className="text-gray-300 font-sans text-xs py-1">
              <strong>Phone:</strong> +66 81 007 8921
            </div>
            <div className="text-gray-300 font-sans text-xs py-1">
              <strong>Email:</strong> info@discgolfthailand.com
            </div>
            <div className="flex flex-col pt-2 pb-10 px-2 w-full">
              <a
                href={`https://www.instagram.com/discgolfthailand/`}
                target="_blank"
              >
                <div className="pl-4 pt-4 text-linkColor font-sans text-base">
                  <div>
                    <FaInstagram
                      className={`mr-2 inline transform scale-150`}
                    />
                    <p className="mt-0 pt-0 inline">Check Instagram here</p>
                  </div>
                </div>
              </a>

              <a
                href={`https://www.tripadvisor.com/Attraction_Review-g11878439-d8154176-Reviews-Leam_Sor_Beach_Disc_Golf_Acoustic_Bar-Na_Mueang_Ko_Samui_Surat_Thani_Province.html`}
                target="_blank"
              >
                <div className="pl-4 pt-4 text-linkColor font-sans text-base">
                  <div>
                    <FaTripadvisor
                      className={`mr-2 inline transform scale-150`}
                    />
                    <p className="mt-0 pt-0 inline">Check Tripadvisor here</p>
                  </div>
                </div>
              </a>

              <a
                href={`https://www.discgolfscene.com/courses/Laem_Sor_Beach_Disc_Golf_and_Acoustic_Cafe`}
                target="_blank"
              >
                <div className="pl-4 pt-4 text-linkColor font-sans text-base">
                  <div>
                    <p className="mt-0 pt-0 inline">Check Disc Golf Scene here</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 flex justify-center py-5">
        <div className="w-full lg:w-9/12 px-10">
          <hr className="bg-gradient-to-r from-gray-800 via-gray-400 to-gray-800 mx-5 my-0"></hr>
        </div>
      </div>
      <div className="bg-gray-800 w-full flex flex-col text-center relative pb-9 lg:pb-0">
        <div className="">
          <span className="text-gray-400 text-sm font-sans">Powered by</span>
        </div>
        <div className="w-full md:w-1/2 flex flex-col md:flex-row items-center justify-center mx-auto md:space-x-12">
          <div>
            <a href="https://www.webbroi.com/" target="_blank">
              <StaticImage
                src="../images/webbROI-logo.png"
                className="my-4 w-40"
                alt="WebbROI"
              />
            </a>
          </div>

          <div>
            <a href="https://www.fastweb.dev/" target="_blank">
              <StaticImage
                src="../images/fw-new-logo-green-for-dark-background.png"
                className="my-4 w-40"
                alt="fastWeb.dev"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="fixed h-9 w-full flex bottom-0 lg:top-0 left-0 z-50 justify-center bg-gray-800 lg:bg-transparent">
        <div className="flex flex-row justify-end items-center h-9 w-full xl:w-9/12 pr-2">
          <div className="flex flex-row space-x-1">
            {/* FB FaFacebookF */}
            <a
              href="https://www.facebook.com/laemsordiscgolf/"
              target="_blank"
              title="Facebook"
            >
              <div className="bg-gray-400 w-7 h-7 rounded-full flex justify-center items-center hover:bg-blue-700 hover:text-gray-300">
                <FaFacebookF />
              </div>
            </a>
            {/* Youtube FaYoutube */}
            <a
              href="https://www.youtube.com/channel/UC0tx_3x-x8WDg8d0Ufgkeiw"
              target="_blank"
              title="Youtube"
            >
              <div className="bg-gray-400 w-7 h-7 rounded-full flex justify-center items-center hover:bg-white hover:text-red-600">
                <FaYoutube />
              </div>
            </a>
            {/* Instagram FaInstagram*/}
            <a
              href="https://www.instagram.com/discgolfthailand/"
              target="_blank"
              title="Instagram"
            >
              <div className="bg-gray-400 w-7 h-7 rounded-full flex justify-center items-center bg-gradient-to-tr hover:from-yellow-400 hover:via-red-500 hover:to-pink-500 ">
                <FaInstagram />
              </div>
            </a>
            {/* GooglePlus FaGooglePlusG*/}
            {/* <a href='https://www.facebook.com/laemsordiscgolf/' target='_blank'>
                        <div className='bg-gray-400 w-7 h-7 rounded-full flex justify-center items-center hover:bg-red-600 hover:text-gray-300'>
                            <FaGooglePlusG />
                        </div>
                    </a> */}
            {/* Air BNB FaAirbnb*/}
            <a
              href="https://www.airbnb.com/s/homes"
              target="_blank"
              title="Air BNB"
            >
              <div className="bg-gray-400 w-7 h-7 rounded-full flex justify-center items-center hover:text-gray-300 bg-gradient-to-t hover:from-pink-600 hover:to-red-500 ">
                <FaAirbnb />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
